import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEventHandler,
} from "react";
import cn from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Settings, User } from "./component";
import Theme from "../Theme";
import {
  InfoState,
  linkedDeviceState,
  linkedDeviceRefresh,
  allCoOwnerState,
  coOwnerApiCall,
  userPersonalDetails,
  isFormVisibleState,
  visibleListAssetState,
  isUserLoginPopupVisibleState,
  firebaseIsFeatureEnableState,
  LoginUserState,
  showCurrentOverlayInMobileState,
  isSignupPopupVisibleState
} from "states";
import { ROUTES } from "routes/constants";

import styles from "./Header.module.sass";
import { useCaptable, useCookie, useLocalStorage, useNetwork } from "hooks";
import { APIS } from "constant";
import { Image } from "@storybook";
import { UseTransaction } from "views/MyWallet/store/hooks";
import UAParser from "ua-parser-js";
import { useCalculateWindowSize } from "@hooks/calculate-window-size";
import NotificationBell from "@components/NotificationBell/NotificationBell";
import { trackEventSG } from "libs";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { imageConfig } from "@utils/imageConfig";
import { ChangeUser } from "views/account/components";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import useDarkMode from "use-dark-mode";
import classNames from "classnames";
import { Button } from "@components/button";
import NewModal from "@components/new-modal/new-modal";
import { AssetForm } from "@views/AssetList";
import { MORNING_LINE } from "@views/exchange/constants";
import { Json } from "types/common";
import SignInForm from "@views/SignIn/components/signin-form/signin-form";

export const Header = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const setInfoData = useSetRecoilState(InfoState);
  const isRefreshDeviceList = useRecoilValue(linkedDeviceRefresh);
  const { tabPermissions, config } = usePermissionContext();
  const setUserLoginPopupVisible = useSetRecoilState(isUserLoginPopupVisibleState);

  const setDevicelist = useSetRecoilState(linkedDeviceState);
  const [isDevicePostApiSuccsess, setIsDevicePostApiSuccsess] = useState(false);
  const [showSignPopUp, setShowSignPopUp] = useRecoilState(isSignupPopupVisibleState);
  const coOwnerList = useRecoilValue(allCoOwnerState);
  const iscoOwnerApiCall = useRecoilValue(coOwnerApiCall);
  const userDetails = useRecoilValue<any>(userPersonalDetails);
  const setIsFormVisible = useSetRecoilState(isFormVisibleState);
  const firebaseIsFeatureEnable = useRecoilValue(firebaseIsFeatureEnableState);

  const { NO_ROUTE } = ROUTES;

  const [visibleListAsset, setVisiblListAsset] = useRecoilState(
    visibleListAssetState
  );

  const setVisibleCurrency = useSetRecoilState(showCurrentOverlayInMobileState);
  const { post: userDeviceData } = useNetwork();
  const { post: checkSeesion, loading } = useNetwork();
  const { get: getInfo, data: infoData } = useNetwork();
  const { getFortressAccountInfo } = UseTransaction();
  const { get: getCookieData } = useCookie();
  const [ipAddress, setIPAddress] = useState("");
  const { get: getlinkedDevice } = useNetwork();
  const { get: getLocalStorage } = useLocalStorage();
  const { width } = useCalculateWindowSize(0);
  const darkMode = useDarkMode(false);
  const {
    logo: { Liquid },
    svg: { ReportBugIcon, avatarWhite },
  } = imageConfig;
  const personalData = useRecoilValue(userPersonalDetails);

  const { openCaptableWindow } = useCaptable();
  const { isLoggedIn } = useRecoilValue(LoginUserState);
  const isMobileScreen = window.innerWidth <= 480;
  const navigation = useMemo(() => {
    return (
      [
        {
          title: "Marketplace",
          url: "/",
          icon: 'ri-store-2-fill'
        },
        // {
        //   title: "News",
        //   url: "/news",
        // },
        {
          title: "Orders",
          url: ROUTES.ORDERSPAGE,
          icon: 'ri-book-read-line'
        },
        {
          title: "Wallet",
          url: ROUTES.WALLET,
          icon: 'ri-wallet-2-line'
        },
        {
          title: "Reports",
          url: "/reports",
          icon: 'ri-file-copy-2-line',
          isHide: !firebaseIsFeatureEnable?.report,
        },
        // {
        //   title: "Auction",
        //   url: "/auction",
        // },
      ]
    )
  }, [firebaseIsFeatureEnable])

  const {
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    signDocStatus,
    kybDocStatus,
  } = personalData?.onboardingData ?? {};

  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus,
  ].some((status) => status === "pending");

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus,
  ].some((status) => status === "rejected");

  const noOneIsPendingAndRejected = !isAnyStatusPending && !isAnyRejected;

  const cookieData = useMemo(() => {
    return getCookieData("userDetails");
  }, []); // Empty dependency array ensures this runs only once

  const isOwner = useMemo(() => {
    const primaryUser: any =
      getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
    if (iscoOwnerApiCall) {
      if (coOwnerList?.length > 0 && !primaryUser) {
        return false;
      }
      return true;
    }
  }, [coOwnerList, iscoOwnerApiCall]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json").then((response) => response.json()).then((data) => setIPAddress(data.ip)).catch((error) => console.log(error));
  }, []);

  let parser = new UAParser(window.navigator.userAgent);
  let result = parser.getResult();

  useEffect(() => {
    if (cookieData?.token) {
      getInfo(APIS.Info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieData?.token]);

  useEffect(() => {
    if (infoData?.data?.stellar) {
      setInfoData(infoData?.data?.stellar);
    }
  }, [infoData]);

  useEffect(() => {
    if (cookieData?.token) {
      getFortressAccountInfo();
    }
  }, [cookieData?.token, getFortressAccountInfo]);

  useEffect(() => {
    const userDetails = getCookieData("userDetails");

    if (isOwner && ipAddress) {
      const payload = {
        deviceIp: ipAddress,
        device: result?.device?.model,
        deviceOS: result?.os?.name,
        deviceBrowser: result?.browser?.name,
        deviceType: result?.device?.type ?? "desktop",
        deviceToken: userDetails?.token,
      };
      if (userDetails?.token) {
        userDeviceData(APIS.UsersDeviceLogin, payload).then((resp) =>
          setIsDevicePostApiSuccsess(true)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCookieData, userDeviceData, ipAddress, isOwner]);

  const getAllLinkedDevice = () => {
    getlinkedDevice(APIS.UsersDeviceLogin).then((resp) => {
      if (resp?.data) {
        setDevicelist(resp?.data);
      }
    });
  };

  useEffect(() => {
    isDevicePostApiSuccsess && getAllLinkedDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevicePostApiSuccsess, isRefreshDeviceList]);

  const handleOnClick = useCallback(() => {
    return setVisibleCurrency(true);
  }, []);

  const handleNavigate = useCallback(
    (url: string) => {
      if (cookieData?.token) {
        const { id: userId } = userDetails;
        navigate(url);
        if (url === ROUTES.WALLET) {
          const eventData = {
            timestamp: new Date(),
            user_id: userId,
          };
          trackEventSG("wallet_page_viewed_web", eventData);
        }
      } else {
        if (url !== '/') {
          setUserLoginPopupVisible(true);
        }
      }
    },
    [userDetails]
  );

  const handleLogoClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains(styles.brand_logo)) {
      navigate(NO_ROUTE);
    }
  };

  const handleReport = () => {
    setIsFormVisible(true);
  };

  // Filter navigation based on config.tabs and permissions
  const filteredTabs: any = useMemo(() => {
    return tabPermissions(navigation);
  }, [tabPermissions, navigation]);

  const handleClose = () => {
    setVisiblListAsset(false);
  };

  const handelListAssetBtn = useCallback(
    () => {
      openCaptableWindow({
        createAsset: "true",
        sessionID: personalData?.sessionId,
      });
    },
    [openCaptableWindow, personalData?.sessionId]
  );

  const handleCaptableOpen = () => {
    if (personalData?.isVerifiedEmail) {
      handelListAssetBtn();
    }

  };

  return (
    <>
      <div className={styles.tablet_wrap}>
        {location.pathname === `/${params?.id}` && <button className={styles.drawer_open_btn} onClick={handleOnClick}>
          <i className="ri-menu-unfold-line" />
        </button>}
        <header className={cn(styles.header)}>
          <div className={cn("excahngecontainer", styles.container)}>
            <div className={styles.logo}>
              <div className={styles.logo1} onClick={handleLogoClick}>
                <Image
                  className={classNames(styles.brand_logo, {
                    [styles.MlcLogo]: config?.name !== "Liquidity",
                  })}
                  fileName={
                    config?.name !== "Liquidity"
                      ? darkMode.value
                        ? config?.branding?.logo?.dark
                        : config?.branding?.logo?.light
                      : Liquid
                  }
                />
              </div>
            </div>
            <div className={styles.wrapper}
                 style={location.pathname !== `/${params?.id}` ? { paddingLeft: "16px" } : {}}>
              <div
                style={{ width: "100%" }}
                className={cn(styles.wrap)}
              >
                <nav className={styles.nav}>
                  {filteredTabs?.filter((item: Json) => !item?.isHide)?.map(
                    (x: any, index: number) => (
                      <div
                        onClick={() => handleNavigate(x.url)}
                        className={cn(styles.navItem, {
                          [styles.activeTab]:
                            x.title === "Marketplace"
                              ? /^\/[A-Z]+$/.test(location.pathname)
                              : location.pathname.includes(x.url),
                        })}
                        key={index}
                      >
                        {x.title}
                      </div>
                    )
                  )}
                </nav>
              </div>
              <div className={styles.containSettings}>
                <ChangeUser />
                {config && config?.name !== MORNING_LINE && (
                  <>
                    {isLoggedIn && (
                      <div
                        id="shiny-cta"
                        className={cn("shiny-cta", styles.listAssetBtn, {
                          [styles.listAssetBtnDisabled]:
                            !personalData?.isVerifiedEmail,
                        })}
                        onClick={handleCaptableOpen}
                      >
                        <span
                          className={styles.listAssetText}
                          style={{ ...(loading && { borderRight: "none" }) }}
                        >
                          List Your Asset For Free
                        </span>
                      </div>
                    )}
                    {!personalData?.isVerifiedEmail && (
                      <ReactTooltip
                        anchorId="shiny-cta"
                        className="theme-tooltip"
                        place="bottom"
                        content="Your email must be verified to proceed"
                      />
                    )}

                    {!isMobileScreen && (
                      <div id="captable-btn">
                        <Button
                          type={`btn-outline-blue ${styles.captable_btn}`}
                          label="Captable"
                          handleClick={() => {
                            openCaptableWindow({
                              sessionID: personalData?.sessionId,
                            });
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {isLoggedIn && (
                  <div className={styles.reportBugIcon} onClick={handleReport}>
                    <img id="reportBug" src={ReportBugIcon} alt="" />
                  </div>
                )}
                <ReactTooltip
                  anchorId="reportBug"
                  className="theme-tooltip"
                  place="bottom"
                  content="Report a bug"
                />

                <Settings className={styles.settings} />
                <div className={styles.user_container}>
                  {isLoggedIn && <NotificationBell />}
                  <Theme className={styles.theme} icon small={undefined} />
                  {!isMobileScreen && !isLoggedIn && <Button
                    type={`${styles.signin_btn}`}
                    label={<>Sign In <Image width="24px" url={avatarWhite} /></>}
                    handleClick={() => setShowSignPopUp(true)}
                  />}
                  {cookieData?.token && <User className={styles.user} />}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className={styles.bottom_nav}>
        {filteredTabs?.filter((item: Json) => !item?.isHide)?.map((tab: any) => (
          <div
            key={tab.title}
            className={`${styles.mlc__nav_item} ${
              tab.title === "Marketplace"
                ? /^\/[A-Z]+$/.test(location.pathname)
                  ? styles.active
                  : ""
                : location.pathname.includes(tab.url)
                  ? styles.active
                  : ""
            }`}
            onClick={() => {
              handleNavigate(tab.url);
            }}
          >
            <i className={`${styles.mlc__nav_item_icon} ${tab.icon}`}></i>
            <span>{tab.title}</span>
          </div>
        ))}
      </div>

      <NewModal
        isOpen={visibleListAsset}
        modalName="List Asset Modal"
        className={`listAssetModal`}
        closeModal={() => setVisiblListAsset(false)}
        title=""
        showCloseBtn={true}
      >
        <AssetForm onClose={handleClose} />
      </NewModal>

      {!isLoggedIn && showSignPopUp && <NewModal
        isOpen={showSignPopUp}
        modalName=""
        className={`exchange-modal-wrapper signin-modal-container-wrapper`}
        closeModal={() => setShowSignPopUp(false)}
        title=""
        showCloseBtn={true}
      >
        <SignInForm inModal />
      </NewModal>}
    </>
  );
};
