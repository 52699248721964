// This component is for android playstore- @awadhesh

import { useCallback, useState } from "react";
import "./request-account-delete.scss";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import {  useSetRecoilState } from "recoil";
import { isSignupPopupVisibleState } from "@states/user";

export const AccountDeletionForm = () => {
  const [reason, setReason] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [successAccountDelete, setSuccessAccountDelete] = useState(false);
  const setShowSignPopUp = useSetRecoilState(isSignupPopupVisibleState);

  const { post: accoountDelete, loading: inviteuserLoading } = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      // Handle submission logic here
      console.log("Account deletion requested for reason:", reason);
      accoountDelete(APIS.ACCOUNT_DELETE, { reason: reason })
        .then((res) => {
          if (res?.success) {
            setReason("");
            setIsButtonEnabled(false);
            setSuccessAccountDelete(true);
            successNotification("Account deletion request submitted");
          } else {
             setShowSignPopUp(true);
            errorNotification(
              res?.message || "Something went wrong. Please try again"
            );
          }
        })
        .catch((err) => {
           setShowSignPopUp(true);
          errorNotification(
            err?.message || "Something went wrong. Please try again"
          );
        });
    },
    [reason]
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setReason(value);
    setIsButtonEnabled(value.trim().length > 0);
  };
  return (
    <>
      {!successAccountDelete ? (
        <div className="account-deletion">
          <h2 className="account-delete-title">Request Account Deletion</h2>
          <p>
          Liquidity.io is committed to safeguarding your data privacy and operating in full compliance with applicable laws
and regulations. Please read the following notice carefully before submitting a request for deletion of your
account and associated data on Liquidity.io platform.
          </p>
          <h3>Pre-Submission Requirements</h3>
          <p>Prior to submitting a deletion request, account holders are required to:</p>
          <ul>
            <li>Cancel all active subscriptions or arrangements, if any.</li>
            <li>Ensure there are no pending or disputed transactions, or unresolved issues.</li>
            <li>
            Resolve any outstanding compliance, regulatory, or legal matters associated with the account, if any.
            </li>
          </ul>
          <h3>Acknowledgement and Legal Consequences</h3>
          <p>By submitting this request, you irrevocably acknowledge and consent to the following:</p>
          <ul>
            <li><span className="list-heading">Permanent Account Deletion : </span>Your Liquidity.io account and associated services will be permanently
            deactivated and rendered inaccessible.</li>
            <li><span className="list-heading">Data Erasure : </span>All personal and business-related data associated with your account will be expunged
            from our active systems, subject to statutory retention obligations as described below.</li>
            <li><span className="list-heading">Service Access Termination : </span>You will permanently lose access to all features, services, data, and
            third-party integrations associated with your account.</li>
            <li><span className="list-heading">Irreversibility : </span>This action is final and non-reversible. Once processed, your account cannot be
            reinstated or recovered.</li>
          </ul>
          <p >
          Your account deletion request will be reviewed and processed within <strong>seven (7) business days</strong> of receipt,
unless extended due to legal or regulatory review. You will receive written confirmation upon successful
completion of the deletion process.
          </p>
          <h3>Data Retention and Legal Compliance</h3>
          <p>Please note that, in accordance with applicable data protection laws, certain data may be retained for a legally
          mandated duration for purposes including but not limited to:</p>
          <ul>
            <li>Compliance with anti-money laundering (AML) and know-your-customer (KYC) obligations.</li>
            <li>Retention of financial or transactional records as required by law.</li>
            <li>
            Enforcement of legal rights or obligations.
            </li>
          </ul>
          <p>Such data will be securely stored and access will be restricted to authorized personnel only.</p>
          <h3>Reason for Account Deletion</h3>
          <i>Please provide a brief reason for your account deletion request. This will help us improve our services.</i>
          <div onSubmit={handleSubmit} className="deletion-form">
            {/* <label htmlFor="reason">
              Reason for Deletion (Please specify):
            </label> */}
            <textarea
              id="reason"
              rows={5}
              cols={50}
              placeholder="Enter your reason for account deletion here..."
              value={reason}
              onChange={(e) => handleChange(e)}
            ></textarea>
            <button
              type="button"
              className="request-deletion"
              onClick={handleSubmit}
              disabled={!isButtonEnabled || inviteuserLoading}
            >
              {" "}
              Request Deletion
            </button>
          </div>
        </div>
      ) : (
        <div className="ThankYou">
          <div className="ThankYou__card">
            <div className="ThankYou__check-icon">
              <i className="ri-check-double-line"></i>
            </div>

            <h1 className="ThankYou__title">Thank You!</h1>

            <div className="ThankYou__message">
              <p className="ThankYou__text">We have received your message.</p>
              <p className="ThankYou__text">
                Our support team will contact you via email within 7 days.
              </p>
              <p className="ThankYou__text">Thank you for your patience!</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDeletionForm;
