import { userPersonalDetails } from "@states/user";
import { SOCIAL_LOGIN_OPTIONS } from "@views/SignIn/components/signin-form/constant";
import { SocialLoginOptions } from "@views/SignIn/components/social-login-options";
import { trackEventGA, trackEventSG } from "libs";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

interface IProps {
  closeModal?: () => void;
}

export const LoginPopUpModal = ({ closeModal }: IProps) => {
  const userDetails = useRecoilValue(userPersonalDetails);

  useEffect(() => {
    trackEventGA("Lqsignuppopup_pageview", {});
    trackEventSG("Lqsignuppopup_pageview", {});
  }, []);

  return (
    <div className="social-pop-up__container">
      <i className="ri ri-close-line close-btn" onClick={closeModal}></i>
      <div>
        <div className="social-pop-up__heading">Sign in using</div>
        <SocialLoginOptions
          customDimensions={{
            width: "64px",
            height: "64px",
            logoWidth: "32",
            logoHeight: "32",
            optionsPostion: "left",
            optionWrapperMarginBlock: "8px 16px",
          }}
          options={SOCIAL_LOGIN_OPTIONS}
        />
      </div>
    </div>
  );
};
