import { GOOGLE_SSO_URL, ONYXPLUS_SSO_URL, ENVIRONMENT } from "envs";
import { v4 } from "uuid";
import { imageConfig } from "@utils/imageConfig";
export interface CountryInfo {
  countryCode: string;
  minLength: number;
  maxLength: number;
}

  export const GCP_ASSETS = {
    LOGIN_CARD_IMAGE: "https://storage.googleapis.com/satschel-assets-public/Liquidity/public/exchange-assets/images/Login_cardImage.png",
  };


  export const COUNTRY_PHONE_LENGTHS: Record<string, CountryInfo> =  {
    "+93": { countryCode: "+93", minLength: 9, maxLength: 9 }, // Afghanistan
    "+54": { countryCode: "+54", minLength: 10, maxLength: 10 }, // Argentina
    "+61": { countryCode: "+61", minLength: 9, maxLength: 9 }, // Australia
    "+43": { countryCode: "+43", minLength: 10, maxLength: 11 }, // Austria
    "+880": { countryCode: "+880", minLength: 10, maxLength: 10 }, // Bangladesh
    "+32": { countryCode: "+32", minLength: 9, maxLength: 9 }, // Belgium
    "+55": { countryCode: "+55", minLength: 10, maxLength: 11 }, // Brazil
    "+1": { countryCode: "+1", minLength: 10, maxLength: 11 }, // Canada/USA
    "+86": { countryCode: "+86", minLength: 11, maxLength: 11 }, // China
    "+45": { countryCode: "+45", minLength: 8, maxLength: 8 }, // Denmark
    "+20": { countryCode: "+20", minLength: 10, maxLength: 10 }, // Egypt
    "+33": { countryCode: "+33", minLength: 9, maxLength: 9 }, // France
    "+49": { countryCode: "+49", minLength: 10, maxLength: 11 }, // Germany
    "+233": { countryCode: "+233", minLength: 9, maxLength: 9 }, // Ghana
    "+353": { countryCode: "+353", minLength: 7, maxLength: 9 }, // Ireland
    "+30": { countryCode: "+30", minLength: 10, maxLength: 10 }, // Greece
    "+91": { countryCode: "+91", minLength: 10, maxLength: 10 }, // India
    "+62": { countryCode: "+62", minLength: 10, maxLength: 11 }, // Indonesia
    "+98": { countryCode: "+98", minLength: 10, maxLength: 10 }, // Iran
    "+39": { countryCode: "+39", minLength: 9, maxLength: 10 }, // Italy
    "+81": { countryCode: "+81", minLength: 10, maxLength: 10 }, // Japan
    "+254": { countryCode: "+254", minLength: 9, maxLength: 9 }, // Kenya
    "+60": { countryCode: "+60", minLength: 9, maxLength: 10 }, // Malaysia
    "+52": { countryCode: "+52", minLength: 10, maxLength: 10 }, // Mexico
    "+977": { countryCode: "+977", minLength: 10, maxLength: 10 }, // Nepal
    "+31": { countryCode: "+31", minLength: 9, maxLength: 9 }, // Netherlands
    "+234": { countryCode: "+234", minLength: 10, maxLength: 10 }, // Nigeria
    "+92": { countryCode: "+92", minLength: 10, maxLength: 10 }, // Pakistan
    "+63": { countryCode: "+63", minLength: 10, maxLength: 10 }, // Philippines
    "+7": { countryCode: "+7", minLength: 10, maxLength: 10 }, // Russia/Kazakhstan
    "+966": { countryCode: "+966", minLength: 9, maxLength: 9 }, // Saudi Arabia
    "+27": { countryCode: "+27", minLength: 9, maxLength: 9 }, // South Africa
    "+82": { countryCode: "+82", minLength: 10, maxLength: 10 }, // South Korea
    "+34": { countryCode: "+34", minLength: 9, maxLength: 9 }, // Spain
    "+90": { countryCode: "+90", minLength: 10, maxLength: 10 }, // Turkey
    "+971": { countryCode: "+971", minLength: 9, maxLength: 9 }, // UAE
    "+44": { countryCode: "+44", minLength: 10, maxLength: 10 }, // UK
    "+84": { countryCode: "+84", minLength: 9, maxLength: 10 }, // Vietnam
  
    // Additional European countries (covering all recognized European countries)
    "+355": { countryCode: "+355", minLength: 9, maxLength: 9 }, // Albania
    "+376": { countryCode: "+376", minLength: 6, maxLength: 9 }, // Andorra
    "+374": { countryCode: "+374", minLength: 8, maxLength: 8 }, // Armenia (partly in Europe)
    "+994": { countryCode: "+994", minLength: 9, maxLength: 9 }, // Azerbaijan (partly in Europe)
    "+375": { countryCode: "+375", minLength: 9, maxLength: 9 }, // Belarus
    "+387": { countryCode: "+387", minLength: 8, maxLength: 9 }, // Bosnia and Herzegovina
    "+359": { countryCode: "+359", minLength: 9, maxLength: 9 }, // Bulgaria
    "+385": { countryCode: "+385", minLength: 9, maxLength: 9 }, // Croatia
    "+357": { countryCode: "+357", minLength: 8, maxLength: 8 }, // Cyprus
    "+420": { countryCode: "+420", minLength: 9, maxLength: 9 }, // Czech Republic
    "+372": { countryCode: "+372", minLength: 8, maxLength: 8 }, // Estonia
    "+358": { countryCode: "+358", minLength: 9, maxLength: 10 }, // Finland
    "+995": { countryCode: "+995", minLength: 9, maxLength: 9 }, // Georgia (partly in Europe)
    "+36": { countryCode: "+36", minLength: 9, maxLength: 9 }, // Hungary
    "+354": { countryCode: "+354", minLength: 7, maxLength: 9 }, // Iceland
    "+371": { countryCode: "+371", minLength: 8, maxLength: 8 }, // Latvia
    "+423": { countryCode: "+423", minLength: 7, maxLength: 9 }, // Liechtenstein
    "+370": { countryCode: "+370", minLength: 8, maxLength: 8 }, // Lithuania
    "+352": { countryCode: "+352", minLength: 9, maxLength: 9 }, // Luxembourg
    "+356": { countryCode: "+356", minLength: 8, maxLength: 8 }, // Malta
    "+373": { countryCode: "+373", minLength: 8, maxLength: 8 }, // Moldova
    "+377": { countryCode: "+377", minLength: 8, maxLength: 9 }, // Monaco
    "+382": { countryCode: "+382", minLength: 8, maxLength: 8 }, // Montenegro
    "+47": { countryCode: "+47", minLength: 8, maxLength: 8 }, // Norway
    "+48": { countryCode: "+48", minLength: 9, maxLength: 9 }, // Poland
    "+351": { countryCode: "+351", minLength: 9, maxLength: 9 }, // Portugal
    "+40": { countryCode: "+40", minLength: 9, maxLength: 9 }, // Romania
    "+378": { countryCode: "+378", minLength: 6, maxLength: 10 }, // San Marino
    "+381": { countryCode: "+381", minLength: 9, maxLength: 9 }, // Serbia
    "+421": { countryCode: "+421", minLength: 9, maxLength: 9 }, // Slovakia
    "+386": { countryCode: "+386", minLength: 8, maxLength: 8 }, // Slovenia
    "+46": { countryCode: "+46", minLength: 9, maxLength: 9 }, // Sweden
    "+41": { countryCode: "+41", minLength: 9, maxLength: 9 }, // Switzerland
    "+380": { countryCode: "+380", minLength: 9, maxLength: 9 }, // Ukraine
    "+379": { countryCode: "+379", minLength: 9, maxLength: 9 }, // Vatican City (uses Italian numbering)
  
    // Additional Middle Eastern countries (covering all commonly recognized Middle Eastern countries)
    "+973": { countryCode: "+973", minLength: 8, maxLength: 8 }, // Bahrain
    "+962": { countryCode: "+962", minLength: 9, maxLength: 9 }, // Jordan
    "+965": { countryCode: "+965", minLength: 8, maxLength: 8 }, // Kuwait
    "+961": { countryCode: "+961", minLength: 8, maxLength: 8 }, // Lebanon
    "+968": { countryCode: "+968", minLength: 8, maxLength: 8 }, // Oman
    "+974": { countryCode: "+974", minLength: 8, maxLength: 8 }, // Qatar
    "+963": { countryCode: "+963", minLength: 9, maxLength: 9 }, // Syria
    "+967": { countryCode: "+967", minLength: 9, maxLength: 9 }, // Yemen
  
    // Additional North American countries (Central America, Caribbean)
    "+1264": { countryCode: "+1264", minLength: 10, maxLength: 10 }, // Anguilla
    "+1268": { countryCode: "+1268", minLength: 10, maxLength: 10 }, // Antigua and Barbuda
    "+1242": { countryCode: "+1242", minLength: 10, maxLength: 10 }, // Bahamas
    "+1246": { countryCode: "+1246", minLength: 10, maxLength: 10 }, // Barbados
    "+501": { countryCode: "+501", minLength: 7, maxLength: 7 }, // Belize
    "+1441": { countryCode: "+1441", minLength: 10, maxLength: 10 }, // Bermuda (uses +1)
    "+506": { countryCode: "+506", minLength: 8, maxLength: 8 }, // Costa Rica
    "+53": { countryCode: "+53", minLength: 8, maxLength: 8 }, // Cuba
    "+1767": { countryCode: "+1767", minLength: 10, maxLength: 10 }, // Dominica
    "+1809": { countryCode: "+1809", minLength: 10, maxLength: 10 }, // Dominican Republic (also uses +1829, +1849)
    "+503": { countryCode: "+503", minLength: 8, maxLength: 8 }, // El Salvador
    "+1473": { countryCode: "+1473", minLength: 10, maxLength: 10 }, // Grenada
    "+502": { countryCode: "+502", minLength: 8, maxLength: 8 }, // Guatemala
    "+509": { countryCode: "+509", minLength: 8, maxLength: 8 }, // Haiti
    "+504": { countryCode: "+504", minLength: 8, maxLength: 8 }, // Honduras
    "+1876": { countryCode: "+1876", minLength: 10, maxLength: 10 }, // Jamaica
    "+505": { countryCode: "+505", minLength: 8, maxLength: 8 }, // Nicaragua
    "+507": { countryCode: "+507", minLength: 8, maxLength: 8 }, // Panama
    "+1787": { countryCode: "+1787", minLength: 10, maxLength: 10 }, // Puerto Rico (uses +1, also +1939)
    "+1869": { countryCode: "+1869", minLength: 10, maxLength: 10 }, // St. Kitts and Nevis
    "+1758": { countryCode: "+1758", minLength: 10, maxLength: 10 }, // St. Lucia
    "+1784": { countryCode: "+1784", minLength: 10, maxLength: 10 }, // St. Vincent and the Grenadines
    "+1868": { countryCode: "+1868", minLength: 10, maxLength: 10 }, // Trinidad and Tobago
  
    // Additional South American countries
    "+591": { countryCode: "+591", minLength: 8, maxLength: 8 }, // Bolivia
    "+56": { countryCode: "+56", minLength: 9, maxLength: 9 }, // Chile
    "+57": { countryCode: "+57", minLength: 10, maxLength: 10 }, // Colombia
    "+593": { countryCode: "+593", minLength: 9, maxLength: 9 }, // Ecuador
    "+592": { countryCode: "+592", minLength: 7, maxLength: 7 }, // Guyana
    "+595": { countryCode: "+595", minLength: 9, maxLength: 9 }, // Paraguay
    "+51": { countryCode: "+51", minLength: 9, maxLength: 9 }, // Peru
    "+597": { countryCode: "+597", minLength: 7, maxLength: 7 }, // Suriname
    "+598": { countryCode: "+598", minLength: 8, maxLength: 8 }, // Uruguay
    "+58": { countryCode: "+58", minLength: 10, maxLength: 10 }, // Venezuela
  };

export interface SocialLoginOption {
  key: "onyxplus" | "facebook" | "google" | "apple" | "microsoft" | 'twitter';
  image?: string;
  disabled?: boolean;
  url?: string;
  hide?: boolean;
  lightBackgroundColour: string;
  darkBackgroundColour: string;
  darkModeImage: string;
  lightModeImage : string
}
export const SOCIAL_LOGIN_OPTIONS: SocialLoginOption[] = [
  {
    key: "google",
    lightModeImage:
      "https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/google.svg",
    darkModeImage:
      "https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/google.svg",
    // url: GOOGLE_SSO_URL + `&nonce=${v4()}`,
    lightBackgroundColour: "#F5F1F1",
    darkBackgroundColour: "#F5F1F1",
  },
  {
    key: "facebook",
    darkModeImage:
      "https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/facebook.svg",
    lightModeImage:
      "https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/facebook.svg",
    disabled: true,
    lightBackgroundColour: "#0866FF",
    darkBackgroundColour: "#0866FF",
  },
  {
    key: "apple",
    lightModeImage: imageConfig?.logo.appleWhite,
    darkModeImage: imageConfig?.logo.appleBlack,
    lightBackgroundColour: "#000000",
    darkBackgroundColour: "#F5F1F1",
  },
  {
    key: "microsoft",
    lightModeImage:
      "https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/microsoft.svg",
    darkModeImage:
      "https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/microsoft.svg",
    lightBackgroundColour: "#D6D6D6",
    darkBackgroundColour: "#3A3B41",
  },
  {
    key: "twitter",
    lightModeImage: imageConfig?.logo.twitter,
    darkModeImage: imageConfig?.logo.twitterBlack,
    lightBackgroundColour: "#000000",
    darkBackgroundColour: "#FFFFFF",
    hide: true,
  },
  {
    key: "onyxplus",
    lightModeImage: `https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/onyxplus.svg`,
    darkModeImage: `https://storage.googleapis.com/satschel-assets-public/images/auth-simplici-media/onyxplus.svg`,
    disabled: false,
    url: ONYXPLUS_SSO_URL + `&nonce=${v4()}`,
    hide: ENVIRONMENT === "prod",
    lightBackgroundColour: "#F5F1F1",
    darkBackgroundColour: "#F5F1F1",
  },
];
