import {
  FC,
  useCallback,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
} from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import {
  ActiveCurrencyState,
  IsOpenAssetLoadingState,
  showCurrentOverlayInMobileState,
} from "states";
import { AddWatchlist } from "components";
import { useCurrencyHook } from "../useCurrencyHook";

import styles from "../CurrencySearch.module.sass";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { Json } from "../../../../../../types/common";
import { mergeStringWithSymbol } from "@utils/getString";
import { assestType, LOAN_ASSETS } from "@views/exchange/constants";


//interface
interface ISearchedRow {
  id: string;
  symbol: string;
  name: string;
  type: string;
  index: number;
  image?: string;
  imageFrom?: string;
  imageTo?: string;
  issuerDetails:Json
}

export const SearchedRow: FC<ISearchedRow> = ({
  id = "",
  symbol,
  name,
  type,
  index,
  image = "",
  imageFrom = "",
  imageTo = "",
  issuerDetails={}
}) => {
  //global states
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const setOpenAssetLoading = useSetRecoilState(IsOpenAssetLoadingState);

  // local states
  const [isError, setIsError] = useState(false);
  const { loadingAsset } = useCurrencyHook();
  const navigate = useNavigate();

  useEffect(() => {
    setOpenAssetLoading(!!loadingAsset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAsset]);

  const item = {
    symbol,
    type,
    id,
    name,
    image,
    imageFrom,
    imageTo,
    issuerDetails
  };
  const setVisibleCurrencyOverlay = useSetRecoilState(showCurrentOverlayInMobileState);

  const handleClickOnSearchedRow = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (symbol !== activeCurrency.symbol) {
        navigate(`/${symbol}`);
        setVisibleCurrencyOverlay(false);
      }
    },
    [activeCurrency.symbol, navigate, symbol]
  );

  const getLogo = useMemo(() => {
    if (type === "forex") {
      if (!isError) {
        return (
          <div className={styles.assetImageContain}>
            <div className={styles.imagewrapper}>
              <div className={styles.imagefromcontiner}>
                <img
                  className={styles.imagefrom}
                  src={imageFrom ?? ""}
                  alt=""
                  onError={() => setIsError(true)}
                />
              </div>
              <div className={styles.imagetocontiner}>
                <img
                  className={styles.imageto}
                  src={imageTo ?? ""}
                  alt=""
                  onError={() => setIsError(true)}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.assetImageContain}>
            <div className={styles.assetLetter}>
              {symbol?.charAt(0).toUpperCase() ?? "?"}
            </div>
          </div>
        );
      }
    }
    return image && !isError ? (
      <img
        className={styles.image}
        src={image}
        alt=""
        onError={() => setIsError(true)}
      />
    ) : (
      <div className={styles.noImageText}>
        {symbol?.charAt(0).toUpperCase() ?? "?"}
      </div>
    );
  }, [image, imageFrom, imageTo, isError, symbol, type]);

  const issuerNameString = type === assestType.crypto ? "" : issuerDetails?.issuerName;

  return (
    <div
      onClick={handleClickOnSearchedRow}
      className={cn(styles.row, {
        [styles.active]: symbol === activeCurrency.symbol,
      })}
      key={index}
    >
      <div className={`${styles.left}`}>
        {getLogo}
        <div className={styles.symbol} data-tooltip-id={id}>
          {type === "music"
            ? symbol : type === "horse_trade" ? symbol 
            : mergeStringWithSymbol(
              issuerNameString,
                symbol?.toUpperCase(),
                "."
              )}
          <ReactTooltip
            id={id}
            place="bottom"
            content={symbol?.toUpperCase()}
            className={"theme-tooltip"}
          />
        </div>
        <div className={styles.compony_name}>
          {type === "music"
            ? name : type === "horse_trade" ? name
            : mergeStringWithSymbol(
              issuerNameString,
                name,
                " "
              )}
        </div>
      </div>
      <div className={`${styles.right} `}>
        {!LOAN_ASSETS[type] && (
          <AddWatchlist item={item} assetType={type} from={"search"} />
        )}
      </div>
    </div>
  );
};
