import { useEffect, } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "components";
import { oauthService } from "./OAuthService";


import "./social-callback.scss";
import { useLogin } from "@views/SignIn/hooks/login";
import { trackEventGA, trackEventSG, useHandleSignInFireBase } from "../../libs";
import { ROUTES } from "@routes/constants";
import { ILoginWithSocialParams } from "@views/SignIn/type";
// @ts-ignore
import { Json } from "@types/common";


export const SocialCallBackComponent = () => {
  const { handleSigninFireBase } = useHandleSignInFireBase();
  const path = useParams();
  const { loginUser } = useLogin()
  const location = useLocation();
  const navigate = useNavigate();
  const { loginWithSocial } = useLogin();

  const login = async () => {
    let idToken: string = ''
    if (path.type === 'onyxplus') {
      const code = new URLSearchParams(window.location.search).get('code');
      const resp = await oauthService.exchangeCodeForToken(code ?? "");
      const payload: Json = {
        type: path.type,
        state: {
          device: "web",
          host: window.location.origin.includes("localhost") ? "https://exchange.stage.satschel.com" : window.location.origin,
          app: "liquidity",
        },
        token: resp?.access_token || ""
      }
      const resData = await loginWithSocial(payload as ILoginWithSocialParams);
      if (resData?.[0]?.url) {
        window.location.href = resData?.[0]?.url;
        return
      }
    } else {
      idToken = new URLSearchParams(location.search).get("token") || "";
    }


    if (!idToken) {
      navigate(ROUTES.LOGIN);
      // errorNotification(resp.message || "Something went wrong")
    } else {
      loginUser(idToken)
      handleSigninFireBase(idToken)
    }

  };

  useEffect(() => {
    login();
    trackEventGA("signupsuccess_pageview");
    trackEventSG("signupsuccess_pageview");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="social-auth-loader">
      <div className="loader">
        <Loader className="loader-blue" />
      </div>

      <p>We are authenticating you...</p>
    </div>
  );
};
